import { ref, computed, watch } from "@vue/composition-api";
// import { title } from '@core/utils/filter'
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import router from "@/router";
import { debounce } from "lodash";

export default function useList() {
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch("plate/getAll");
  store.dispatch("plate_list/getAll");
  store.dispatch("vehicle_brand/getAll");
  store.dispatch("vehicle_model/getAll");

  const listOptions = computed(() => store.getters["plate_list/getSelectedItems"]);
  const listFilter = ref(null);

  const listOptionsBrand = computed(
    () => store.getters["vehicle_brand/getSelectedItems"]
  );
  const brandFilter = ref(null);

  const listOptionsModel = computed(
    () => store.getters["vehicle_model/getSelectedItems"]
  );
  const modelFilter = ref(null);

  const listOptionsType = [
    { value: "Sedán", label: "Sedán" },
    { value: "Hatchback", label: "Hatchback" },
    { value: "Coupé", label: "Coupé" },
    { value: "SUV", label: "SUV" },
    { value: "Station Wagon", label: "Station Wagon" },
    { value: "Crossover", label: "Crossover" },
    { value: "Convertibles", label: "Convertibles" },
    { value: "MPV", label: "MPV" },
    { value: "Pick Up", label: "Pick Up" },
    { value: "Moto", label: "Moto" },
    { value: "Camion", label: "Camion" },
  ];
  const typeFilter = ref(null);

  if (router.currentRoute.params.name)
    listFilter.value = parseInt(router.currentRoute.name);

  const tableColumns = [
    { label: "actions.name", key: "actions" },
    { label: "plate.title", key: "plate", sortable: true },
    { label: "plate.description", key: "description", sortable: true },
    {
      label: "plate.list",
      key: "detection_lists",
      sortable: false,
      formatter: (value, key, item) => {
        if (item.detection_lists)
          return [
            ...new Set(
              item.detection_lists.map((item) => {
                return item.name;
              })
            ),
          ].join(", ");
        return "";
      },
    },
    { label: "plate.brand", key: "vehicle_brand.name", sortable: true },
    { label: "plate.model", key: "vehicle_model.name", sortable: true },
    { label: "plate.color", key: "color", sortable: true },
    { label: "plate.type", key: "type", sortable: true },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("plate/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_detection_list: listFilter.value,
        id_brand: brandFilter.value,
        id_model: modelFilter.value,
        type: typeFilter.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch(
    [listFilter, currentPage, perPage, brandFilter, modelFilter, typeFilter],
    () => {
      refetchData();
    }
  );

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    listOptionsModel,
    modelFilter,
    brandFilter,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    listOptionsBrand,
    listOptions,
    listFilter,
    refetchData,
    listOptionsType,
    typeFilter,
  };
}
