<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{
            `${isAdd ? $t("actions.add") : $t("actions.edit")} ${$tc("plate.title", 2)}`
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Plate -->
          <validation-provider #default="validationContext" name="plate" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$tc('plate.title', 2)"
              label-for="plate"
            >
              <b-form-input
                id="plate"
                v-model="itemData.plate"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('plate.description')"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="itemData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Detection Lists -->
          <validation-provider #default="validationContext" name="detection_lists">
            <b-form-group
              :label="$tc('plate.list', 2)"
              label-for="detection_lists"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.detection_lists"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="listOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="detection_lists"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider name="vehicle">
            <b-form-checkbox
              id="vehicle"
              v-model="vehicle"
              name="vehicle"
              value="1"
              unchecked-value="0"
              class="my-1text-capitalize"
              switch
            >
              {{ $t("plate.vehicle_data") }}
            </b-form-checkbox>
          </validation-provider>

          <div v-if="vehicle == 1 ? true : false">
            <validation-provider #default="validationContext" name="brand_lists">
              <b-form-group
                :label="$t('plate.brand')"
                label-for="brand_lists"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="brand"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listOptionsBrand"
                  :clearable="true"
                  :reduce="(val) => val.value"
                  input-id="brand_lists"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="validationContext" name="model">
              <b-form-group
                :label="$t('plate.model')"
                label-for="model"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="model"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listOptionsModels"
                  :clearable="true"
                  :reduce="(val) => val.value"
                  input-id="model"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="validationContext" name="color">
              <b-form-group
                class="text-capitalize"
                :label="$t('plate.color')"
                label-for="color"
              >
                <b-form-input
                  id="color"
                  v-model="itemData.color"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="validationContext" name="type_list">
              <b-form-group
                :label="$t('plate.type')"
                label-for="type_list"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="itemData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="type"
                  :clearable="true"
                  :reduce="(val) => val.value"
                  input-id="type_list"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    listOptions: {
      type: Array,
      required: true,
    },

    isAdd: {
      type: Boolean,
      required: true,
    },
    listOptionsBrand: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const type = [
      { value: "Sedán", label: "Sedán" },
      { value: "Hatchback", label: "Hatchback" },
      { value: "Coupé", label: "Coupé" },
      { value: "SUV", label: "SUV" },
      { value: "Station Wagon", label: "Station Wagon" },
      { value: "Crossover", label: "Crossover" },
      { value: "Convertibles", label: "Convertibles" },
      { value: "MPV", label: "MPV" },
      { value: "Pick Up", label: "Pick Up" },
      { value: "Moto", label: "Moto" },
      { value: "Camion", label: "Camion" },
    ];
    const vehicle = ref("");
    const model = ref("");
    const brand = ref("");
    const listOptionsModels = computed(() => store.getters["vehicle_brand/getItem"]);
    const blankData = {
      plate: "",
      description: "",
      brand: "",
      model: "",
      color: "",
      type: "",
    };
    const getModel = () => {
      console.log("funciona");
    };
    const selectModel = ref(true);

    const listOptionsModel = [];

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
      vehicle.value = 0;
      brand.value = "";
      model.value = "";
      store.dispatch("vehicle_brand/clear");
    };

    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        itemData.value.brand = brand.value;
        itemData.value.model = model.value;
        dispatch = store.dispatch("plate/add", itemData.value);
      } else {
        const req = {
          id: itemData.value.id,
          params: {
            plate: itemData.value.plate,
            description: itemData.value.description,
            detection_lists: itemData.value.detection_lists,
            brand: brand.value,
            color: itemData.value.color,
            type: itemData.value.type,
            model: model.value,
          },
        };

        dispatch = store.dispatch("plate/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
        if (itemData.value.vehicle_brand.id) {
          vehicle.value = 1;
          store.dispatch("vehicle_brand/get", itemData.value.vehicle_brand.id);
          model.value = itemData.value.vehicle_model.id;
        }
        brand.value = itemData.value.vehicle_brand.id;
        itemData.value.detection_lists = props.itemEdit.detection_lists
          ? props.itemEdit.detection_lists.map((detection_list) => detection_list.id)
          : null;
      }
    };

    watch(brand, (id) => {
      store.dispatch("vehicle_brand/get", id);
    });

    return {
      type,
      getModel,
      selectModel,
      listOptionsModel,
      itemData,
      onSubmit,
      vehicle,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      brand,
      listOptionsModels,
      model,
    };
  },
};
</script>